.panel {
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 2px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.panel-body {
  padding: 1em;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  .ant-tabs-tab {
    padding: 8px !important;
  }

  .attribute_quantity{
    p{
      span{
        width: 20px;
        height: 20px;
      }
    }
  }
  
}

.panel-heading {
  padding: 1em;

  #admin_main_panel & {
    padding-bottom: 10px;
  }

  > .dropdown .dropdown-toggle {
    color: inherit;
  }

  p {
    margin: 7px 0 0;
  }
}

.panel-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #313745;
  letter-spacing: 0.2px;
  margin-bottom: 0;

  .panel-primary & {
    color: #fff;
  }

  > a,
  > small,
  > .small,
  > small > a,
  > .small > a {
    color: inherit;
  }
}

.panel-footer {
  padding: 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #dddddd;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}
.list-group + .panel-footer {
  border-top-width: 0;
}

.panel-group {
  margin-bottom: 17px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 2px;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel-heading {
  border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #dddddd;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #dddddd;
}
.panel-default {
  border-color: #d2d6df;
}
.panel-default > .panel-heading {
  color: #262b36;
  background-color: #9fa8bc;
  border-color: #d2d6df;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d2d6df;
}
.panel-default > .panel-heading .badge {
  color: #9fa8bc;
  background-color: #262b36;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d2d6df;
}

.panel-primary {
  .panel-body {
    border: 1px solid #e8e8e8;
    border-top: none;

    .panel-heading {
      background-color: #4F66A0;
      border-color: #4F66A0;
    }
  }
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: $c-secondary;
  border-color: $c-secondary;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: $c-secondary;
}

.panel-body {
  .panel-heading {
    background-color: #4F66A0;
    border-color: #4F66A0;
  }
}

.panel-primary > .panel-heading .badge {
  color: $c-secondary;
  background-color: #ffffff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: $c-secondary;
}

.panel-success {
  border-color: #1c7781;
}
.panel-success > .panel-heading {
  color: #1aa71c;
  background-color: #4F66A0;
  border-color: #1c7781;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #1c7781;
}
.panel-success > .panel-heading .badge {
  color: #4F66A0;
  background-color: #1aa71c;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #1c7781;
}
.panel-info {
  border-color: #31b0d5;
}
.panel-info > .panel-heading {
  color: #3b83a6;
  background-color: #5bc0de;
  border-color: #31b0d5;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #31b0d5;
}
.panel-info > .panel-heading .badge {
  color: #5bc0de;
  background-color: #3b83a6;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #31b0d5;
}
.panel-warning {
  border-color: #df9730;
}
.panel-warning > .panel-heading {
  color: #9c732c;
  background-color: #e6ad5c;
  border-color: #df9730;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #df9730;
}
.panel-warning > .panel-heading .badge {
  color: #e6ad5c;
  background-color: #9c732c;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #df9730;
}
.panel-danger {
  border-color: #c9302c;
}
.panel-danger > .panel-heading {
  color: #d9534f;
  background-color: #d9534f;
  border-color: #c9302c;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #c9302c;
}
.panel-danger > .panel-heading .badge {
  color: #d9534f;
  background-color: #d9534f;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #c9302c;
}

.content-wrapper {
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    flex:1;
  }
}
